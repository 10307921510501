<template>
  <div>
    <van-row type="flex" justify="center">
      <van-image
        round
        width="100"
        height="100"
        :src="mFaceimg"
        style="margin: 40px 0px"
      />
    </van-row>
    <van-cell-group style="margin: 0px 30px">
      <van-form @submit="onSubmit" label-width="50px">
        <van-field
          v-model="mUsername"
          name="fUser"
          :label="$t('lang.username')"
          :placeholder="$t('lang.username_input')"
          :rules="[{ required: true }]"
        />
        <van-field
          v-model="mPwd"
          name="fPwd"
          type="password"
          :label="$t('lang.password')"
          :placeholder="$t('lang.password_input')"
          :rules="[{ required: true }]"
        />
        <van-field
          v-model="mCode"
          name="fCode"
          center
          clearable
          :label="$t('lang.captcha')"
          :placeholder="$t('lang.captcha')"
          maxlength="4"
          :rules="[{ required: true }]"
        >
          <template #button>
            <van-image
              width="130"
              height="38"
              :src="mCaptcha"
              @click="getCaptcha"
            />
          </template>
        </van-field>
        <div style="margin: 16px">
          <van-button color="#669999" block native-type="submit">
            {{ $t("lang.login") }}
          </van-button>
        </div>
        <div style="margin: 16px; text-align: right; font-size: 14px">
          <router-link
            v-show="mCompanyID != undefined && mCompanyID != ''"
            :to="{ name: 'Register', params: { cid: mCompanyID } }"
            style="color: #0184b2"
            >{{ $t("lang.register") }}</router-link
          >
        </div>
        <div><br /></div>
      </van-form>
    </van-cell-group>
    <!--div
      style="
        position: fixed;
        bottom: 0;
        left: 0;
        color: #fff;
        font-size: 14px;
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
      "
    >
      金海格智能巡检管理系统
    </div-->
  </div>
</template>

<script>
import querystring from "querystring";
import { GetCaptcha, Login } from "@/api/index.js";
import { setSessionStr, setSessionObj } from "@/utils/session.js";

export default {
  data() {
    return {
      mUsername: "",
      mPwd: "",
      mCode: "",
      mCaptcha: "",
      mFaceimg: "",
      verKey: "",
      mCompanyID: "",
    };
  },

  mounted() {
    let objUser = querystring.parse(location.search.replace("?", ""));
    //console.log(objUser);
    this.$store.commit("setOpenID", objUser.pid);
    this.$store.commit("setFaceUrl", objUser.furl);
    this.$store.commit("setCompanyId", objUser.cid);
    this.mFaceimg = this.$store.getters.getFace;
    this.mCompanyID = objUser.cid;
    //console.log(this.mCompanyID);
    if (objUser.pid != undefined && objUser.pid != "") {
      this.UserLogin(objUser.pid, "", "", "", "");
    }
    this.getCaptcha();
  },

  methods: {
    getCaptcha() {
      GetCaptcha((ret) => {
        this.verKey = ret.key;
        this.mCaptcha = ret.image;
      });
    },

    onSubmit(fr) {
      let openid = this.$store.state.openid;
      if (openid == undefined || openid == "") {
        this.$Toast(this.$t("lang.wechatopen_hint"));
        //return;
      }
      this.UserLogin(
        openid,
        fr.fUser.trim(),
        fr.fPwd.trim(),
        fr.fCode.trim(),
        this.verKey
      );
    },

    UserLogin(_openid, _user, _pwd, _code, _key) {
      Login(
        {
          openId: _openid,
          userName: _user,
          password: _pwd,
          captcha: _code,
          verKey: _key,
        },
        (ret) => {
          if (ret) {
            if (ret.code == 0) {
              this.$store.commit("setToken", ret.data.token);
              this.$store.commit("setUser", ret.data.user);
              this.$store.commit("setCompanys", ret.data.companys);
              this.$store.commit("setDepts",this.$Tools.sortBykey(ret.data.depts, "id"));
              this.$store.commit("setGroup", ret.data.group);
              this.$store.commit("setInspector", ret.data.inspector);
              this.$store.commit("setPermission", ret.data.permission);
              this.$store.commit("setPlace", ret.data.place);
              this.$store.commit("setRoutes",this.$Tools.sortBykey(ret.data.routes, "id"));
              this.$store.commit("setDevices", ret.data.devices==undefined?[]:ret.data.devices);
              

              //初始化查询条件
              let date1 = this.$Tools.FormatDate2(new Date()) + " 00:00:01";
              let date2 = this.$Tools.FormatDate2(new Date()) + " 23:59:59";
              setSessionStr("Query_Date", this.$t("lang.today"));
              setSessionStr("Query_Date_1", date1);
              setSessionStr("Query_Date_2", date2);

              let com_name =
                ret.data.companys.length > 0 ? ret.data.companys[0].name : "";
              let com_id =
                ret.data.companys.length > 0 ? ret.data.companys[0].id : 0;
              setSessionStr("Query_Company", com_name);
              setSessionStr("Query_Company_id", com_id);

              let dept_name = "";
              let dept_id = 0;
              if (ret.data.depts.length > 0) {
                let lstData = ret.data.depts.filter((d) => {
                  return d.companyId == com_id;
                });
                dept_name = lstData.length > 0 ? lstData[0].name : "";
                dept_id = lstData.length > 0 ? lstData[0].id : 0;
              }
              setSessionStr("Query_Dept", dept_name);
              setSessionStr("Query_Dept_id", dept_id);
              setSessionStr("Query_RepairType", "-1");
              setSessionStr("Query_Record_miss", "0");
              setSessionStr("Query_Record_local", "1");

              //参数
              let par = {
                mCompany: com_id,
                mDept: dept_id,
                mDate1: date1,
                mDate2: date2,
                mLine: "",
                mAddr: "",
                mGroup: "",
                mUser: "",
                mDevice:"",
                mRecord: "-1",
                mRecord_miss: "0",
                mRecord_local: "1",
                mState: "",
                mOrder: "desc",
                mTotalType: "4",
                mRepairType: "-1",
                mKeyword: "",
                pagesize: 20,
              };
              setSessionObj("Query_Params", par);

              this.$store.commit("setIsXunjianApp", true);
              this.$router.replace({ path: "/query/appindex" });

            } else if (ret.code != 9) this.$Toast(ret.message);
          } else this.$Toast(this.$t("lang.login_fail"));
        }
      );
    },
  },

  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#336666");
  },
  //#0184b2
  beforeDestroy() {
    document.querySelector("body").setAttribute("style", "");
  },
};
</script>
